import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from './config';
const URL = import.meta.env.VITE_BACKOFFICE_API_URL;

export const backOfficeApiService = createApi({
  reducerPath: 'backOfficeApi',
  baseQuery: baseQuery(URL),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  tagTypes: ['BackOffice', 'GetSpotShipmentList', 'GetSpotShipmentOfferStatusCount', 'companyList'],
  endpoints: () => ({})
});
